.App {
  font-family: sans-serif;
  text-align: center;
}
.container {
  width: 60%;
  max-width: 900px;
  margin: 0 auto;
}
* {
  margin: 0;
}

.whatsapp-icon {
  margin-top: 16px;
}

/* for mobile */
@media screen and (max-width: 767px) {
  .whatsapp-icon {
    margin-top: 10px;
  }

  .whatsapp_float {
    width: 40px;
    height: 40px;
    bottom: 20px;
    right: 10px;
    font-size: 22px;
  }
}
