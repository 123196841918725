.youtube {
    position: relative;
    width: 100%;
    padding-top: 56.25%;
}

.iframe {
    position: relative;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
}

@media screen and (max-width:767px) {
    .iframe {
        position: relative;

        iframe {
            width: 100% !important;
            height: 100% !important;
        }
    }
}
